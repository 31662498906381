import { useRouter } from "next/router"
import { useEffect } from "react"

function HomePage() {
  const router = useRouter()
  useEffect(() => {
    router.replace("/simulation/50000")
  }, [router])
  return <></>
}

export default HomePage
